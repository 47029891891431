import React from 'react'
import { Container } from 'react-bootstrap'
import './home.css'
const Home = () => {
    return (
        <Container fluid className='home2'>
            <h1 className='display-1' style={{color: "#fff", fontWeight: "bold", letterSpacing: "15px", textAlign: "center"}}>WELCOME</h1>
        </Container>
    )
}

export default Home