import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faFolder,
  faEnvelope,
  faCircleInfo
} from "@fortawesome/free-solid-svg-icons";
import "../css/navbar.css";

const NavbarComponent = () => {
  return (
    <div className="header">
      <div className="navbar">
        <NavLink to="/" activeclassname="active" exact="true">
          <FontAwesomeIcon icon={faHouse} title="Home" />
        </NavLink>
        <NavLink to="/services" activeclassname="active">
          <FontAwesomeIcon icon={faFolder} title="Work" />
        </NavLink>
        <NavLink to="/contact" activeclassname="active">
          <FontAwesomeIcon icon={faEnvelope} title="Contact" />
        </NavLink>
        <NavLink to="/about-us" activeclassname="active">
          <FontAwesomeIcon icon={faCircleInfo} title="About Us" />
        </NavLink>
      </div>
    </div>
  );
};

export default NavbarComponent;
