import React, { useState, useContext } from "react";
import { UserContext } from "./UserContext";
import { Container, Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./auth.css";

const LogIn = () => {
  // Therritja e UserContext i cili do te mbaje te dhenave te userit
  const { setUserInfo } = useContext(UserContext);
  // Navigimi ne nje komponente tjeter
  const nav = useNavigate();
  // Cdo input ka nje state ku do te ruhen informacionet
  const [userLog, setUserLog] = useState({
    email: "",
    password: "",
  });
  // Marrja e informacioneve nga input-et
  const handleChange = (e) => {
    setUserLog({ ...userLog, [e.target.name]: e.target.value });
  };
  // State per shfaqen e gabimit
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  // Funksioni i log in, i cili do te kaloje info ne backend per verifikim
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Disa validime nga frontend
    if (!userLog.email) {
      setError("Email should not be empty");
      setAlert("danger");
      return;
    }
    if (userLog.password.length < 8) {
      setError("Password should have at least 8 characters!");
      setAlert("danger");
      return;
    }
    // Therritja e apit
    // Informacionet do te kalohen tek route per kontrollin e te dhenave
    await axios
      .post("http://localhost:5000/login/", userLog, {
        withCredentials: true, // kalon cookies me ane te kerkeses (perdoret per token qe eshte marre nga jwt)
      })
      .then((res) => {
        // Nese user logohen te dhenat ruhen ne nje cookie/session
        // Testim
        console.log(res.data);
        // Behet update tek UserContext
        setUserInfo(res.data);
        // Pasi eshte loguar dergohet tek faqja kryesore
        // Mund te dergohen edhe ne komponente te tjere
        nav("/admin");
      })
      // Nese nuk ndodh login
      .catch((err) => {
        console.log("User not login", err);
        setAlert("danger");
        if (
          err.res &&
          err.res.status === 404 &&
          err.res.data === "User not found"
        ) {
          setError("Incorrect email. Please check your email and try again.");
        } else {
          setError(
            "Login failed. Please check your credentials and try again."
          );
        }
      });
  };

  return (
    <Container className="my-5 mx-auto">
      {/* Therritja e funksionit */}
      {/* Cdo input duhet te kete atributin name, value dhe eventin onChange */}
      <Form id="form" className="mt-5 w-75 mx-auto" onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            onChange={handleChange}
            name="email"
            value={userLog.email}
            placeholder="Enter email"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            onChange={handleChange}
            value={userLog.password}
            placeholder="Enter password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
      <p className="w-75 mt-3 mx-auto have-account">
        Don't have an account? <a href="/admin/register">Register</a>
      </p>
      {/* Shfaqja e gabimeve */}
      <Alert variant={alert} className="w-75 mt-3 mx-auto">
        {error}
      </Alert>
    </Container>
  );
};

export default LogIn;
