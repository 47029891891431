import React from 'react'
import "../css/footer.css"

const FooterComponent = () => {
  return (
    <div className='footer'>
      <p>&copy; 2024 Digiflex AL. All rights reserved.</p>
    </div>
  )
}

export default FooterComponent
