import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/about-us.css";
import digiflexLogo from "../images/Client Logos/Digiflex Logo-01.png";
import kilicaLogo from "../images/Client Logos/Kilica Logo-01-01-01.png";
import lizardLogo from "../images/Client Logos/Lizard Logo-01-01.png";
import client1 from "../images/Client Logos/ABI Bank Logo-01.png";
import client2 from "../images/Client Logos/Albas Logo-01.png";
import client3 from "../images/Client Logos/Arteg Logo-01.png";
import client4 from "../images/Client Logos/Biote Logo-01.png";
import client5 from "../images/Client Logos/Cineplexx Logo-01.png";
import client6 from "../images/Client Logos/FSHZH Logo-01.png";
import client7 from "../images/Client Logos/KESH Logo-01.png";
import client8 from "../images/Client Logos/ManeTci Logo-01.png";
import client9 from "../images/Client Logos/News24 Logo-01.png";
import client10 from "../images/Client Logos/Orion Logo-01.png";
import client11 from "../images/Client Logos/Segafredo Logo-01.png";
import client12 from "../images/Client Logos/Selita Logo.png";

const AboutUsComponent = () => {
  const clients = [
    {
      clientLogo: client1,
      clientAlt: "ABI Bank Logo",
    },
    {
      clientLogo: client2,
      clientAlt: "Albas Logo",
    },
    {
      clientLogo: client3,
      clientAlt: "Arteg Logo",
    },
    {
      clientLogo: client4,
      clientAlt: "Biote Logo",
    },
    {
      clientLogo: client5,
      clientAlt: "Cineplexx Logo",
    },
    {
      clientLogo: client6,
      clientAlt: "FSHZ Logo",
    },
    {
      clientLogo: client7,
      clientAlt: "KESH Logo",
    },
    {
      clientLogo: client8,
      clientAlt: "ManeTci Logo",
    },
    {
      clientLogo: client9,
      clientAlt: "News24 Logo",
    },
    {
      clientLogo: client10,
      clientAlt: "Orion Logo",
    },
    {
      clientLogo: client11,
      clientAlt: "Segafredo Logo",
    },
    {
      clientLogo: client12,
      clientAlt: "Selita Logo",
    },
  ];
  return (
        <Container className="about-us">
          <div id="logoDivision">
            <img src={digiflexLogo} alt="Digiflex Logo" />
          </div>
          <div>
            <p>
              Discover the art of digital printing at Digiflex, where each
              project is a masterpiece. Our commitment to delivering exceptional
              print quality is unwavering. From concept to creation, we focus on
              bringing your ideas to life with precision and care. Elevate your
              brand with Digiflex, where quality printing meets unparalleled
              service.
            </p>
            <p id="partnerTitle">Partnered with:</p>
            <div id="partnerLogoDivision">
              <div className="partner-logo">
                <img src={kilicaLogo} alt="Kilica Studio Logo" title="Kilica Studio"/>
              </div>
              <div className="partner-logo">
                <img src={lizardLogo} alt="Lizard Printing Logo" title="Lizard Printing"/>
              </div>
            </div>
          </div>
          <div className="clients-section">
            <p>
              At Digiflex, client satisfaction is at the heart of what we do.
              Join our community of happy clients and experience excellence in
              digital printing. Have a look at some of our satisfied clients:
            </p>
            <Row>
              {clients.map((client, index) => {
                return (
                  <Col className="client-logo" key={index} sm={6} md={6} lg={4}>
                    <img src={client.clientLogo} alt={client.clientAlt} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
  );
};

export default AboutUsComponent;
