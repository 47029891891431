import React from 'react'

const Page404 = () => {
  return (
    <div className='d-flex justify-content-center align-items-center display-1' style={{ height: "50vh" }}>
      404
    </div>
  )
}

export default Page404
