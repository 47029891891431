import "./App.css";
import "./css/main.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { UserContextProvider } from "./components/Auth/UserContext";
import NavbarComponent from "./components/NavbarComponent";
import HomeComponent from "./components/HomeComponent";
import WorkComponent from "./components/WorkComponent";
import ContactComponent from "./components/ContactComponent";
import AboutUsComponent from "./components/AboutUsComponent";
import FooterComponent from "./components/FooterComponent";
import BadgePrice from "./components/Price Components/BadgePrice";
import BookPrice from "./components/Price Components/BookPrice";
import BrochurePrice from "./components/Price Components/BrochurePrice";
import BusinessCardPrice from "./components/Price Components/BusinessCardPrice";
import CalendarPrice from "./components/Price Components/CalendarPrice";
import EnvelopePrice from "./components/Price Components/EnvelopePrice";
import FlyerPrice from "./components/Price Components/FlyerPrice";
import MagazinePrice from "./components/Price Components/MagazinePrice";
import NotebookPrice from "./components/Price Components/NotebookPrice";
import PinPrice from "./components/Price Components/PinPrice";
import PostcardPrice from "./components/Price Components/PostcardPrice";
import PosterPrice from "./components/Price Components/PosterPrice";
import NavigationBar from "./components/Navigate/NavigateBar";
import Home from "./components/Home/Home";
import ReadAll from "./components/CRUD/ReadAll";
import ReadOne from "./components/CRUD/ReadOne";
import Create from "./components/CRUD/Create";
import Update from "./components/CRUD/Update";
import Page404 from "./components/404/Page404";
import Register from "./components/Auth/Register";
import LogIn from "./components/Auth/LogIn";

function App() {
  const location = useLocation();
  const hideNavbar = location.pathname.startsWith("/admin");
  const hideUserContextContent = !location.pathname.startsWith("/admin");

  return (
    <div className="body">
      {!hideNavbar && <NavbarComponent />}
      <Routes>
        <Route exact path="/" element={<HomeComponent />} />
        <Route exact path="/services" element={<WorkComponent />} />
        <Route exact path="/contact" element={<ContactComponent />} />
        <Route exact path="/about-us" element={<AboutUsComponent />} />
        <Route exact path="/services/badge-price" element={<BadgePrice />} />
        <Route exact path="/services/book-price" element={<BookPrice />} />
        <Route
          exact
          path="/services/brochure-price"
          element={<BrochurePrice />}
        />
        <Route
          exact
          path="/services/business-card-price"
          element={<BusinessCardPrice />}
        />
        <Route
          exact
          path="/services/calendar-price"
          element={<CalendarPrice />}
        />
        <Route
          exact
          path="/services/envelope-price"
          element={<EnvelopePrice />}
        />
        <Route exact path="/services/flyer-price" element={<FlyerPrice />} />
        <Route
          exact
          path="/services/magazine-price"
          element={<MagazinePrice />}
        />
        <Route
          exact
          path="/services/notebook-price"
          element={<NotebookPrice />}
        />
        <Route exact path="/services/pin-price" element={<PinPrice />} />
        <Route
          exact
          path="/services/postcard-price"
          element={<PostcardPrice />}
        />
        <Route exact path="/services/poster-price" element={<PosterPrice />} />
      </Routes>
      {!hideUserContextContent && (
        <UserContextProvider>
          <NavigationBar />
          <Routes>
            {/* Home */}
            <Route path="/admin" element={<Home />} />
            {/* CRUD */}
            <Route path="/admin/readAll" element={<ReadAll />} />
            <Route path="/admin/readOne/:id" element={<ReadOne />} />
            <Route path="/admin/create" element={<Create />} />
            <Route path="/admin/update/:id" element={<Update />} />
            {/* Auth  */}
            <Route path="/admin/register" element={<Register />} />
            <Route path="/admin/login" element={<LogIn />} />
            {/* 404 */}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </UserContextProvider>
      )}
      <FooterComponent />
    </div>
  );
}

export default App;

// import { Routes, Route } from 'react-router-dom';
// import { UserContextProvider } from './components/Auth/UserContext';
// import NavigationBar from './components/Navigate/NavigateBar';
// import FooterBar from './components/Navigate/FooterBar';
// import Home from './components/Home/Home'
// import ReadAll from './components/CRUD/ReadAll';
// import ReadOne from './components/CRUD/ReadOne';
// import Create from './components/CRUD/Create';
// import Update from './components/CRUD/Update';
// import Page404 from './components/404/Page404';
// import Register from './components/Auth/Register';
// import LogIn from './components/Auth/LogIn';

// function App() {
//   return (
//     <>
//       {/* Therritja e useContext */}
//       <UserContextProvider>
//         <NavigationBar />
//         <Routes>
//           {/* Home */}
//           <Route path="/" element={<Home />} />
//           {/* CRUD */}
//           <Route path="/readAll" element={<ReadAll />} />
//           <Route path="/readOne/:id" element={<ReadOne />} />
//           <Route path="/create" element={<Create />} />
//           <Route path="/update/:id" element={<Update />} />
//           {/* Auth  */}
//           <Route path="/register" element={<Register />} />
//           <Route path="/login" element={<LogIn />} />
//           {/* 404 */}
//           <Route path="*" element={<Page404 />} />
//         </Routes>
//         <FooterBar />
//       </UserContextProvider>
//     </>
//   );
// }

// export default App;
