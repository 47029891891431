import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faAt,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "../css/contact.css";
import callErmal from "../js/callErmal";
import callDritan from "../js/callDritan";
import sendEmail from "../js/sendEmail";
import getDirections from "../js/getDirections";

const ContactComponent = () => {
  return (
    <div className="main">
      <div className="contact">
        <div className="location">
          <iframe
            title="Location Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.750819668137!2d19.80416577651657!3d41.3142841005257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031eb35b7f591%3A0x71fb6cef4374073e!2sDigiflex%20Albania!5e0!3m2!1sen!2s!4v1681837059246!5m2!1sen!2s"
            width="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="contact-info">
          <h1>Contact Us</h1>
          <div className="contact-details">
            <div id="phoneNumber1">
              <button onClick={callErmal}>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  Ermal Asllani: +355 69 226 3355
                </p>
              </button>
            </div>
            <div id="phoneNumber2">
              <button onClick={callDritan}>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  Dritan Mero: +355 69 207 1199
                </p>
              </button>
            </div>
            <div id="email">
              <button onClick={sendEmail}>
                <p>
                  <FontAwesomeIcon icon={faAt} className="icon" />
                  Send Email: info@digiflex.al
                </p>
              </button>
            </div>
            <div id="address">
              <button onClick={getDirections}>
                <p>
                  <FontAwesomeIcon icon={faLocationDot} className="icon" />
                  <span>
                    Address: Rruga "Medar Shtylla", Kompleksi Kika 1, Tiranë
                  </span>
                </p>
              </button>
            </div>
          </div>

          <div id="socialMedia">
            <div className="mediaIcon">
              <a href="/">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </div>
            <div className="mediaIcon">
              <a href="/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
            <div className="mediaIcon">
              <a href="/">
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
