import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import {
  faHouse,
  faBookOpenReader,
  faFolderPlus,
  faRightFromBracket,
  faUserPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../Auth/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Navigate = () => {
  const nav = useNavigate();
  // Do te perdoret per te mbajtur dhe ndryshuar info per user e loguar
  const { userInfo, setUserInfo } = useContext(UserContext);
  // Merr info nga backend per user-in e loguar
  useEffect(
    () => {
      if (!userInfo.email) {
        axios
          .get("http://localhost:5000/user/", {
            withCredentials: true,
          })
          .then((res) => {
            setUserInfo(res.data);
          })
          .catch((err) => {
            console.error("Error fetching user data:", err);
          });
      }
    },
    // useEffect do te therritet sa here qe behet nje ndryshim tek UserContext
    [userInfo, setUserInfo]
  );

  // Funksioni log out, fshin informacionet na UserContext
  const handelLogout = () => {
    // { withCredentials: true } kalon cookies me ane te kerkese (perdoret per token qe eshte marre nga jwt)
    axios
      .post("http://localhost:5000/logout", null, { withCredentials: true })
      .then((res) => {
        // Fshin te dhenat nga UserContext
        setUserInfo({});
        // Kalon te komponenti i login
        nav("/admin/login");
      })
      // Nese nuk ndodh logout
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  return (
    <div className="header">
      <div className="navbar">
        <NavLink to="/" activeclassname="active" exact="true">
          <FontAwesomeIcon icon={faHouse} title="Home" />
        </NavLink>
        <NavLink to="/admin/readAll" activeclassname="active">
          <FontAwesomeIcon icon={faBookOpenReader} title="Read All" />
        </NavLink>
        {userInfo.email ? (
          <>
            <NavLink to="/admin/create" activeclassname="active">
              <FontAwesomeIcon icon={faFolderPlus} title="Create" />
            </NavLink>
            <NavLink
              to="/admin/login"
              onClick={handelLogout}
              activeclassname="active"
            >
              <FontAwesomeIcon icon={faRightFromBracket} title="Log Out" />
            </NavLink>
          </>
        ) : (
          <>
            <NavLink to="/admin/register" activeclassname="active">
              <FontAwesomeIcon icon={faUserPlus} title="Register" />
            </NavLink>
            <NavLink to="/admin/login" activeclassname="active">
              <FontAwesomeIcon icon={faUser} title="Log In" />
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default Navigate;
