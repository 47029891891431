import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/work.css";
import BadgeImg from "../images/Service Logos/badge-01.jpg";
import BcardImg from "../images/Service Logos/bcard-01.jpg";
import BookImg from "../images/Service Logos/book-01.jpg";
import BrochureImg from "../images/Service Logos/brochure-01.jpg";
import CalendarImg from "../images/Service Logos/calendar-01.jpg";
import EnvelopeImg from "../images/Service Logos/envelope-01.jpg";
import FlyerImg from "../images/Service Logos/flyer-01.jpg";
import MagazineImg from "../images/Service Logos/magazine-01.jpg";
import NotebookImg from "../images/Service Logos/notebook-01.jpg";
import PinImg from "../images/Service Logos/pin-01.jpg";
import PostcardImg from "../images/Service Logos/postcard-01.jpg";
import PosterImg from "../images/Service Logos/poster-01.jpg";

const WorkComponent = () => {
  const services = [
    {
      serviceImage: BadgeImg,
      serviceTitle: "Badges",
      serviceLink: "/services/badge-price"
    },
    {
      serviceImage: BcardImg,
      serviceTitle: "Bussines Cards",
      serviceLink: "/services/business-card-price"
    },
    {
      serviceImage: BookImg,
      serviceTitle: "Books",
      serviceLink: "/services/book-price"
    },
    {
      serviceImage: BrochureImg,
      serviceTitle: "Brochures",
      serviceLink: "/services/brochure-price"
    },
    {
      serviceImage: CalendarImg,
      serviceTitle: "Calendars",
      serviceLink: "/services/calendar-price"
    },
    {
      serviceImage: EnvelopeImg,
      serviceTitle: "Envelopes",
      serviceLink: "/services/envelope-price"
    },
    {
      serviceImage: FlyerImg,
      serviceTitle: "Flyers",
      serviceLink: "/services/flyer-price"
    },
    {
      serviceImage: MagazineImg,
      serviceTitle: "Magazines",
      serviceLink: "/services/magazine-price"
    },
    {
      serviceImage: NotebookImg,
      serviceTitle: "Notebooks",
      serviceLink: "/services/notebook-price"
    },
    {
      serviceImage: PinImg,
      serviceTitle: "Pins",
      serviceLink: "/services/pin-price"
    },
    {
      serviceImage: PostcardImg,
      serviceTitle: "Postcards",
      serviceLink: "/services/postcard-price"
    },
    {
      serviceImage: PosterImg,
      serviceTitle: "Posters",
      serviceLink: "/services/poster-price"
    },
  ];
  return (
    <div className="main">
      <Container fluid className="work">
        <div className="services">
          <div className="title">
            <p id="title">Our Services and Prices</p>
            <p id="description">
              Caution: Our service prices are subject to variation depending on
              file complexity and specific requirements. For accurate pricing,
              please contact our customer service at digiflex.al@gmail.com for a
              personalized quote tailored to your project's needs. Your
              understanding is appreciated, and we look forward to serving you.
            </p>
          </div>
          <Row>
            {services.map((service, index) => {
              return (
                <Col key={index} s={12} md={6} lg={4}>
                  <div className="service-item">
                    <a href={service.serviceLink}>
                      <img
                        src={service.serviceImage}
                        alt={service.serviceTitle}
                      />
                      <p className="overlay-text">{service.serviceTitle}</p>
                    </a>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default WorkComponent;
