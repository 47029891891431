import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeImg from "../images/Color Splash.webp";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../css/home.css";
import { NavLink } from "react-router-dom";
import digiflexLogo from "../images/Client Logos/Digiflex Logo-01.png";

const HomeComponent = () => {
  return (
    <div className="main">
      <div className="home">
        <div className="text-part">
          <img id="mainLogo" src={digiflexLogo} alt="Digiflex Logo" />
          <p id="slogan">DIGITAL PRINTING SOLUTIONS.</p>
        </div>
        <div className="picture-part">
          <img src={HomeImg} alt="Man Sketching a Design" />
          <NavLink to="/about-us" className={"button"}>
            <FontAwesomeIcon icon={faAngleRight} />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
